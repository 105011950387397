import { NLPSearchResultFormat, type NLPSearchType } from '../../../ai-answer-dialog/types';
import { type CuratedDefinitionType } from '../gql/curated-definition-query';

export const mapCuratedDefinitionToNLPSearchType = (
	curatedDefinition: CuratedDefinitionType,
): NLPSearchType => {
	const curatedDefinitionReference = curatedDefinition.confluenceEntity;

	return {
		nlpResult: curatedDefinition.definition,
		uniqueSources: curatedDefinitionReference
			? [
					{
						id: curatedDefinitionReference?.id || '',
						title: curatedDefinitionReference?.title || '',
						url: curatedDefinition.referenceUrl || '',
						type: curatedDefinitionReference?.type || '',
						lastModified: '',
						iconUrl: '',
						spaceName: curatedDefinitionReference?.space?.name || '',
						spaceUrl: null,
					},
				]
			: [],
		nlpResultEditor: {
			name: curatedDefinition.editor?.name || '',
			id: curatedDefinition.editor?.accountId || '',
		},
		disclaimer: null,
		errorState: null,
		format: NLPSearchResultFormat.MARKDOWN,
	};
};
