import { useCallback, useContext, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import { ConfluenceSiteAri } from '@atlassian/ari/confluence/site';
import { SearchAIAnswerQuery, NLPSearchErrorState, AIAnswerAPIType } from '@atlassian/search-ai';
import type {
	AIAnswerQueryFunctionType,
	SearchAIAnswerQueryType,
	SearchAIAnswerQueryVariables,
} from '@atlassian/search-ai';
import {
	sainRestFetchHandlerAssistanceService,
	sainStreamFetchHandlerAssistanceService,
} from '@atlassian/search-ai/stream-fetch';

import { getConfluenceContentAri } from '@confluence/content-types-utils';
import { useBooleanFeatureFlag, useSessionData } from '@confluence/session-data';
import { getAGGClient } from '@confluence/graphql';
import { useContentType } from '@confluence/page-context';
import { ExperienceTrackerContext, READING_AIDS_EXPERIENCE } from '@confluence/experience-tracker';
import { useSpaceId } from '@confluence/space-utils';
import { fg } from '@confluence/feature-gating';

import type {
	CuratedDefinitionQuery as CuratedDefinitionQueryType,
	CuratedDefinitionQueryVariables,
} from './gql/__types__/CuratedDefinitionQuery';
import { CuratedDefinitionQuery } from './gql/CuratedDefinitionQuery.agggraphql';
import {
	generatePageARI,
	generateWorkspaceId,
	mapCuratedDefinitionToNLPSearchType,
} from './reading-aids-utils';

type useAISearchSupplierArgs = {
	contentId: string;
	onResult: (result: SearchAIAnswerQueryType) => void;
};

type AISearchSupplierType = {
	aiSearchQueryFunction: AIAnswerQueryFunctionType;
	loading: boolean;
	assistanceServiceEnabled: boolean;
};

const unexpectedErrors = [NLPSearchErrorState.Default, NLPSearchErrorState.NetworkError];

const i18n = defineMessages({
	searchAiDialogQueryPretext: {
		id: 'contextual-reading-aids.search-ai-dialog.query-pretext',
		defaultMessage: 'what is {queryText}?',
		description: 'Search query prompt for a highlighted term that a user wants to be defined',
	},
});

const SPACE_RESOURCE_TYPE = 'space';

export const useAISearchSupplier = ({
	contentId,
	onResult,
}: useAISearchSupplierArgs): AISearchSupplierType => {
	const aggClient = getAGGClient();
	const intl = useIntl();

	const experienceTracker = useContext(ExperienceTrackerContext);
	const [contentType] = useContentType();
	const { activationId, cloudId } = useSessionData();
	const cloudIdARI = ConfluenceSiteAri.create({
		siteId: cloudId,
	}).toString();
	const pageARI = generatePageARI(contentId, cloudId, contentType);
	const spaceId = useSpaceId();
	const spaceARI = spaceId
		? getConfluenceContentAri({
				siteId: cloudId,
				resourceType: SPACE_RESOURCE_TYPE,
				resourceId: spaceId,
				activationId: '',
			})
		: '';
	const workspaceId = generateWorkspaceId(activationId, cloudId);

	const [loading, setLoading] = useState(true);

	const assistanceServiceEnabled = fg('reading_aids_use_assistance_service');

	const READING_AIDS_ASSISTANCE_SERVICE_FETCH_TIMEOUT = 10000;

	const isAssistanceServiceStreamingEnabled = useBooleanFeatureFlag(
		'platform.reading-aids.assistant-service-streaming-enabled',
	);

	const fetchAIGeneratedQueryStreaming = useCallback(
		({ variables }: { variables: Omit<SearchAIAnswerQueryVariables, 'cloudIdARI'> }) => ({
			type: AIAnswerAPIType.STREAM,
			stream: sainStreamFetchHandlerAssistanceService({
				variables: {
					...variables,
					cloudIdARI,
					query: intl.formatMessage(i18n.searchAiDialogQueryPretext, {
						queryText: variables.query,
					}),
				},
				isReadingAids: true,
				fetchConfig: { entity_ari: pageARI },
				generatorConfig: {
					yieldAnswerPart: true,
					yieldFinalResponse: true,
				},
			}),
		}),
		[cloudIdARI, pageARI, intl],
	);

	const fetchAIGeneratedQuery = useCallback(
		({
			variables,
		}: {
			variables: Omit<SearchAIAnswerQueryVariables, 'followUpsEnabled' | 'cloudIdARI'>;
		}) => {
			if (assistanceServiceEnabled && isAssistanceServiceStreamingEnabled) {
				return fetchAIGeneratedQueryStreaming({ variables });
			}

			const aiGeneratedQuery = assistanceServiceEnabled
				? sainRestFetchHandlerAssistanceService({
						variables: {
							...variables,
							query: intl.formatMessage(i18n.searchAiDialogQueryPretext, {
								queryText: variables.query,
							}),
							cloudIdARI,
						},
						isReadingAids: true,
						fetchConfig: {
							entity_ari: pageARI,
							timeout: READING_AIDS_ASSISTANCE_SERVICE_FETCH_TIMEOUT,
						},
					}).then((result) => ({
						data: result,
					}))
				: aggClient.query<SearchAIAnswerQueryType, SearchAIAnswerQueryVariables>({
						query: SearchAIAnswerQuery,
						variables: {
							...variables,
							query: intl.formatMessage(i18n.searchAiDialogQueryPretext, {
								queryText: variables.query,
							}),
							cloudIdARI,
						},
					});

			return aiGeneratedQuery.then((aiResult) => {
				if (aiResult.data.nlpSearch.errorState) {
					if (unexpectedErrors.includes(aiResult.data.nlpSearch.errorState)) {
						experienceTracker.fail({
							name: READING_AIDS_EXPERIENCE,
							error: {
								name: aiResult.data.nlpSearch.errorState,
								message: aiResult.data.nlpSearch.errorState,
							},
							attributes: {
								contentId,
							},
						});
					} else {
						experienceTracker.abort({
							name: READING_AIDS_EXPERIENCE,
							reason: aiResult.data.nlpSearch.errorState,
							attributes: {
								contentId,
							},
						});
					}
				}
				onResult(aiResult.data);
				setLoading(false);
				return aiResult.data;
			});
		},
		[
			aggClient,
			assistanceServiceEnabled,
			cloudIdARI,
			contentId,
			experienceTracker,
			onResult,
			pageARI,
			intl,
			fetchAIGeneratedQueryStreaming,
			isAssistanceServiceStreamingEnabled,
		],
	);

	const aiSearchQueryFunction: AIAnswerQueryFunctionType = useCallback(
		async ({
			variables,
		}: {
			variables: Omit<SearchAIAnswerQueryVariables, 'followUpsEnabled' | 'cloudIdARI'>;
		}) => {
			try {
				return await aggClient
					.query<CuratedDefinitionQueryType, CuratedDefinitionQueryVariables>({
						query: CuratedDefinitionQuery,
						variables: {
							workspaceId,
							keyPhrase: variables.query,
							contentId: pageARI,
							spaceId: spaceARI,
						},
						fetchPolicy: 'network-only',
					})
					.then((result) => {
						if (!result.data.knowledgeDiscovery.definition.message) {
							const data = {
								nlpSearch: mapCuratedDefinitionToNLPSearchType(
									result.data.knowledgeDiscovery.definition,
								),
							};
							onResult(data);
							setLoading(false);
							return data;
						} else {
							throw new Error(result.data.knowledgeDiscovery.definition.message);
						}
					});
			} catch (error) {
				return fetchAIGeneratedQuery({ variables });
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[
			aggClient,
			assistanceServiceEnabled,
			cloudIdARI,
			contentId,
			experienceTracker,
			onResult,
			pageARI,
			workspaceId,
			intl,
			fetchAIGeneratedQuery,
		],
	);

	return {
		aiSearchQueryFunction,
		loading,
		assistanceServiceEnabled,
	};
};
