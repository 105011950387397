import { useCallback, useState } from 'react';

import { defineMessages, useIntl } from 'react-intl-next';

import { useAIAnswerContext } from '../../../ai-answer-dialog/ai-answer-context';
import {
	AIAnswerAPIType,
	type AIAnswerQueryFunctionType,
	type SearchAIAnswerQueryVariables,
} from '../../../ai-answer-dialog/types';
import { fetchAggResponse } from '../../../common';
import {
	type FetchConfig as FetchConfigType,
	sainRestFetchHandlerAssistanceService,
	sainStreamFetchHandlerAssistanceService,
} from '../../../stream-api/stream-fetch';
import { fetchCuratedDefinitionErrorPayload } from '../error-analytics';
import {
	CuratedDefinitionQuery,
	type CuratedDefinitionQueryType,
	type CuratedDefinitionQueryVariables,
} from '../gql/curated-definition-query';
import type { DefinitionConfluenceScope, DefinitionJiraScope } from '../gql/types';

import { mapCuratedDefinitionToNLPSearchType } from './utils';

type useDefinitionSupplierArgs = {
	workspaceId: string;
	cloudIdARI: string;
	fetchConfig?: FetchConfigType;
	confluenceScopeId?: DefinitionConfluenceScope;
	jiraScopeId?: DefinitionJiraScope;
	isDefinitionCurationEnabled?: boolean;
	isAssistantServiceStreamingEnabled?: boolean;
};

type DefinitionSupplierType = {
	fetchDefinition: AIAnswerQueryFunctionType;
	loading: boolean;
};

const i18n = defineMessages({
	searchAiDialogQueryPretext: {
		id: 'contextual-reading-aids.search-ai-dialog.query-pretext',
		defaultMessage: 'what is {queryText}?',
		description: 'Search query prompt for a highlighted term that a user wants to be defined',
	},
});

const NO_CURATED_DEF_FOUND_MESSAGE = 'No matching scope for Definition';

export const useDefinitionSupplier = ({
	workspaceId,
	cloudIdARI,
	fetchConfig,
	confluenceScopeId,
	jiraScopeId,
	isDefinitionCurationEnabled,
	isAssistantServiceStreamingEnabled,
}: useDefinitionSupplierArgs): DefinitionSupplierType => {
	const intl = useIntl();
	const [loading, setLoading] = useState(true);
	const { fireAnalyticsEvent, commonAttributes } = useAIAnswerContext();

	const fetchAIGeneratedDefinition = useCallback(
		async ({ variables }: { variables: Omit<SearchAIAnswerQueryVariables, 'cloudIdARI'> }) => {
			const { query, locale, additional_context, experience, followUpsEnabled } = variables;

			if (isAssistantServiceStreamingEnabled) {
				return {
					type: AIAnswerAPIType.STREAM,
					stream: sainStreamFetchHandlerAssistanceService({
						variables: {
							query: intl.formatMessage(i18n.searchAiDialogQueryPretext, {
								queryText: query,
							}),
							locale,
							cloudIdARI,
							additional_context,
							experience,
							followUpsEnabled,
						},
						isReadingAids: true,
						fetchConfig,
						generatorConfig: {
							yieldAnswerPart: true,
							yieldFinalResponse: true,
						},
						onStreamFinalResponse: () => {
							setLoading(false);
						},
					}),
				};
			}

			const restResponse = await sainRestFetchHandlerAssistanceService({
				variables: {
					query: intl.formatMessage(i18n.searchAiDialogQueryPretext, {
						queryText: query,
					}),
					locale,
					cloudIdARI,
					additional_context,
					experience,
				},
				isReadingAids: true,
				fetchConfig,
			});
			setLoading(false);
			return restResponse;
		},
		[cloudIdARI, fetchConfig, isAssistantServiceStreamingEnabled, intl],
	);

	const fetchDefinition = useCallback(
		async ({ variables }: { variables: Omit<SearchAIAnswerQueryVariables, 'cloudIdARI'> }) => {
			if (isDefinitionCurationEnabled) {
				try {
					const result = await fetchAggResponse<
						CuratedDefinitionQueryVariables,
						CuratedDefinitionQueryType
					>({
						graphQuery: CuratedDefinitionQuery,
						variables: {
							workspaceId,
							keyPhrase: variables.query,
							confluenceScopeId,
							jiraScopeId,
						},
					});

					if (result.data && !result.data.knowledgeDiscovery.definition.message) {
						const data = {
							nlpSearch: mapCuratedDefinitionToNLPSearchType(
								result.data.knowledgeDiscovery.definition,
							),
						};
						setLoading(false);
						return data;
					} else if (result.data && result.data.knowledgeDiscovery.definition.message) {
						throw new Error(result.data.knowledgeDiscovery.definition.message);
					} else {
						throw new Error('Something went wrong with fetching curated definition');
					}
				} catch (error) {
					// want to fire event when error.message does not equal the no curated definition message
					// returned by the query in data.knowledgeDiscovery.definition.message
					if (
						error instanceof Object &&
						'message' in error &&
						error.message !== NO_CURATED_DEF_FOUND_MESSAGE
					) {
						fireAnalyticsEvent(fetchCuratedDefinitionErrorPayload(error, commonAttributes));
					}
					return fetchAIGeneratedDefinition({ variables });
				}
			}

			return fetchAIGeneratedDefinition({ variables });
		},
		[
			confluenceScopeId,
			jiraScopeId,
			fetchAIGeneratedDefinition,
			isDefinitionCurationEnabled,
			workspaceId,
			commonAttributes,
			fireAnalyticsEvent,
		],
	);

	return { fetchDefinition, loading };
};
