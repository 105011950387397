import gql from 'graphql-tag';

export const SearchAIAnswerQuery = gql`
	query ConfluenceAISearchQuery(
		$query: String!
		$locale: String!
		$cloudIdARI: String!
		$additional_context: String
		$followUpsEnabled: Boolean = false
		$experience: String
	) {
		nlpSearch(
			query: $query
			locale: $locale
			additionalContext: $additional_context
			followups_enabled: $followUpsEnabled
			locations: [$cloudIdARI]
			experience: $experience
		) @optIn(to: "Nlp Search") {
			nlpResults {
				nlpResult
				sources {
					id
					title
					url
					iconUrl
					type
					lastModified
					spaceName
					spaceUrl
				}
			}
			format
			nlpFollowUpResults {
				followUps
			}
			uniqueSources {
				id
				title
				url
				iconUrl
				type
				lastModified
				spaceName
				spaceUrl
			}
			errorState
			disclaimer
		}
	}
`;
