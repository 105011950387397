// query to check whether curated definition exists
import type { DefinitionConfluenceScope, DefinitionJiraScope } from '../types';

export interface CuratedDefinitionType {
	definition?: string;
	accountId?: string;
	referenceUrl?: string | null;
	editor?: {
		name: string;
		accountId: string;
	} | null;
	confluenceEntity?: {
		id: string;
		type: string | null;
		title: string | null;
		space: {
			name: string | null;
		} | null;
	} | null;
	message?: string | null;
}

export interface CuratedDefinitionQueryType {
	knowledgeDiscovery: {
		definition: CuratedDefinitionType;
	};
}

export interface CuratedDefinitionQueryVariables {
	workspaceId: string;
	keyPhrase: string;
	confluenceScopeId?: DefinitionConfluenceScope;
	jiraScopeId?: DefinitionJiraScope;
}

export const CuratedDefinitionQuery = `
query CuratedDefinitionQuery(
		$workspaceId: String!
		$keyPhrase: String!
		$confluenceScopeId: KnowledgeDiscoveryDefinitionScopeIdConfluence
		$jiraScopeId: KnowledgeDiscoveryDefinitionScopeIdJira
	) {
		knowledgeDiscovery {
			definition(
				workspaceId: $workspaceId
				keyPhrase: $keyPhrase
				confluenceScopeId: $confluenceScopeId
				jiraScopeId: $jiraScopeId
			) @optIn(to: "KnowledgeDiscovery Get definition") {
				__typename
				... on KnowledgeDiscoveryDefinition {
					definition
					accountId
					createdAt
					editor {
						... on User {
							name
							accountId
						}
					}
					referenceUrl
					confluenceEntity {
						... on ConfluencePage {
							id
							type
							title
							space {
								name
							}
						}
						... on ConfluenceBlogPost {
							id
							type
							title
							space {
								name
							}
						}
					}
				}
				... on QueryError {
					message
				}
			}
		}
	}
`;
