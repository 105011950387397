import gql from 'graphql-tag';

export const CuratedDefinitionQuery = gql`
	query CuratedDefinitionQuery(
		$workspaceId: String!
		$keyPhrase: String!
		$contentId: String!
		$spaceId: String!
	) {
		knowledgeDiscovery {
			definition(
				workspaceId: $workspaceId
				keyPhrase: $keyPhrase
				contentId: $contentId
				spaceId: $spaceId
			) @optIn(to: "KnowledgeDiscovery Get definition") {
				__typename
				... on KnowledgeDiscoveryDefinition {
					definition
					accountId
					createdAt
					editor {
						... on User {
							name
							accountId
						}
					}
					referenceUrl
					confluenceEntity {
						... on ConfluencePage {
							id
							type
							title
							space {
								name
							}
						}
						... on ConfluenceBlogPost {
							id
							type
							title
							space {
								name
							}
						}
					}
				}
				... on QueryError {
					message
				}
			}
		}
	}
`;
