/* eslint @atlaskit/ui-styling-standard/enforce-style-prop: 0 */
import { styled } from '@compiled/react';
import type { FC } from 'react';
import React, {
	Fragment,
	forwardRef,
	useEffect,
	useState,
	useMemo,
	useCallback,
	useRef,
} from 'react';
import ReactDOM from 'react-dom';
import { defineMessages, useIntl } from 'react-intl-next';
import uuid from 'uuid/v4';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import FeatureGates from '@atlaskit/feature-gate-js-client';
import DocumentsIcon from '@atlaskit/icon/glyph/documents';
import FileIcon from '@atlaskit/icon/glyph/file';
import OfficeBuildingIcon from '@atlaskit/icon/glyph/office-building';
import QuoteIcon from '@atlaskit/icon/glyph/quote';
import type { PopupComponentProps } from '@atlaskit/popup';
import Popup from '@atlaskit/popup';
import { useThemeObserver } from '@atlaskit/tokens';

import { ConfluenceSiteAri } from '@atlassian/ari/confluence/site';
import type { SearchAIAnswerQueryType } from '@atlassian/search-ai';
import {
	AiBrand,
	EditedDefinitionScope,
	SearchAIDialog,
	useDefinitionSupplier,
} from '@atlassian/search-ai';

import { getConfluenceContentAri } from '@confluence/content-types-utils';
import { ExperienceSuccess, READING_AIDS_EXPERIENCE } from '@confluence/experience-tracker';
import { useInlineDialogCloseManager } from '@confluence/forge-ui';
import { useContentType } from '@confluence/page-context';
import { useBooleanFeatureFlag, useSessionData } from '@confluence/session-data';
import { useSpaceId } from '@confluence/space-utils';
import {
	PERSISTED_KEYS_ON_SERVER,
	confluenceLocalStorageInstance as localStorage,
} from '@confluence/storage-manager';
import { fg } from '@confluence/feature-gating';

import { useAISearchSupplier } from './useAISearchSupplier';

export const AI_UPSELL_ENABLED_FF = 'platform.ai.enable-ai-upsell';

const READING_AIDS_SHOW_FOLLOW_UP_FF = 'platform.reading-aids.show-follow-up-in-ui';
const WORKSPACE_RESOURCE_TYPE = 'workspace';

const READING_AIDS_CONTEXT_MAX_CHARACTERS = 400;

export type SelectionRect = {
	top: number;
	left: number;
	width: number;
	height: number;
};

type ReadingAidsPopupProps = {
	selectedText: string;
	additionalContext?: string;
	selectionRange?: Range;
	selectionRect: SelectionRect | null;
	onResult: (result: SearchAIAnswerQueryType) => void;
	isReadingAidsOpen: boolean;
	onClose: () => void;
	contentId: string;
	popupPortalContainerId: string;
	isAutohighlighted?: boolean;
	sessionId?: string;
};

const CustomPopupContainer = forwardRef<HTMLDivElement, PopupComponentProps>(
	({ children, ...props }, ref) => {
		return (
			<div {...props} ref={ref}>
				{children}
			</div>
		);
	},
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/design-system/no-empty-styled-expression -- Ignored via go/DSP-18766
const PopupWrapper = styled.div({});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const CurrentSelectionSpan = styled.span<{
	top: number;
	left: number;
	width: number;
	height: number;
}>({
	opacity: 0,
	position: 'absolute',
	pointerEvents: 'none',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	top: `${(props: { top: number }) => props.top}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	left: `${(props: { left: number }) => props.left}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	width: `${(props: { width: number }) => props.width}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	height: `${(props: { height: number }) => props.height}px`,
});

export const extractReadingAidsContext = (range: Range | undefined): string => {
	const container = range?.startContainer.parentElement;
	if (!range || !container) {
		return '';
	}
	if (
		container.tagName === 'SPAN' ||
		container.tagName === 'A' ||
		container.tagName === 'STRONG' ||
		container.tagName === 'CODE'
	) {
		return container.parentElement
			? container.parentElement.textContent || container.innerText
			: '';
	}
	return container.textContent || container.innerText;
};

const i18n = defineMessages({
	editDefinitionRestrictedToSpaceOption: {
		id: 'contextual-reading-aids.edit.definition.restricted.to.space.option',
		defaultMessage: 'Anyone in this space',
		description: 'Text for space restriction option',
	},
	editDefinitionRestrictedToPageOption: {
		id: 'contextual-reading-aids.edit.definition.restricted.to.page.option',
		defaultMessage: 'Anyone on this page',
		description: 'Text for page restriction option',
	},
	editDefinitionRestrictedToBlogpostOption: {
		id: 'contextual-reading-aids.edit.definition.restricted.to.blogpost.option',
		defaultMessage: 'Anyone viewing this blogpost',
		description: 'Text for blogpost restriction option',
	},
	editDefinitionRestrictedToSiteOption: {
		id: 'contextual-reading-aids.edit.definition.restricted.to.site.option',
		defaultMessage: 'Anyone on this site',
		description: 'Text for site restriction option',
	},
});

const LOADING_CONTAINER_ID = 'reading-aids-invisible-loading-portal';

export const InvisibleLoadingContainer = () => {
	return (
		<Fragment>
			<div
				style={{ position: 'absolute', top: 0, left: 0, width: 0, height: 0 }}
				id={LOADING_CONTAINER_ID}
			/>
		</Fragment>
	);
};

const READING_AIDS_ASSISTANCE_SERVICE_FETCH_TIMEOUT = 10000;

export const ReadingAidsPopup: FC<ReadingAidsPopupProps> = ({
	selectedText,
	additionalContext,
	selectionRange,
	selectionRect,
	isReadingAidsOpen,
	onResult,
	onClose,
	contentId,
	popupPortalContainerId,
	isAutohighlighted,
	sessionId,
}) => {
	const { cloudId, userId, edition, activationId, isRovoEnabled } = useSessionData();
	const { formatMessage } = useIntl();

	const [contentType] = useContentType();
	const spaceId = useSpaceId();
	const readingAidsSessionId = useRef(!!sessionId ? sessionId : uuid()).current;
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const isReadingAidsShowFollowUpEnabled = useBooleanFeatureFlag(READING_AIDS_SHOW_FOLLOW_UP_FF);
	const { colorMode } = useThemeObserver();
	const isDarkMode = colorMode === 'dark';

	const portalContainer = document.querySelector(`#${popupPortalContainerId}`);
	const [popupRef, setPopupRef] = useState<HTMLElement | null>(null);
	const [triggerRef, setTriggerRef] = useState<HTMLElement | null>(null);
	const documentRef = useRef<HTMLDivElement | null>(
		document.querySelector(`#${LOADING_CONTAINER_ID}`),
	);
	const answerLoadingRef = useRef(false);
	const [query, setQuery] = useState<string>('');
	const source = 'readingAids';
	const loadingHighlightRect: SelectionRect | undefined = isAutohighlighted
		? selectionRect && portalContainer
			? {
					...selectionRect,
					top: selectionRect.top + portalContainer.getBoundingClientRect().top,
					left: selectionRect.left + portalContainer.getBoundingClientRect().left,
				}
			: undefined
		: selectionRange?.getBoundingClientRect();

	const persistedAutoHighlightState = localStorage.getItemAsBoolean(
		PERSISTED_KEYS_ON_SERVER.PERSISTED_READING_AIDS_AUTO_HIGHLIGHT,
	);
	const [autoHighlightsDisabled, setAutoHighlightsDisabled] = useState<boolean>(
		persistedAutoHighlightState,
	);

	const isAssistanceServiceStreamingEnabled = useBooleanFeatureFlag(
		'platform.reading-aids.assistant-service-streaming-enabled',
	);

	const newLoadingStateEnabled =
		fg('reading_aids_new_loading_state') &&
		FeatureGates.getExperimentValue<string>(
			'confluence_reading_aids_loading_state_experiment',
			'cohort',
			'control',
		) === 'experiment';

	const cloudIdARI = ConfluenceSiteAri.create({
		siteId: cloudId,
	}).toString();
	const contentARI = contentType
		? getConfluenceContentAri({
				siteId: cloudId,
				resourceType: contentType,
				resourceId: contentId,
				activationId: '',
			})
		: '';
	const spaceARI = spaceId
		? getConfluenceContentAri({
				siteId: cloudId,
				resourceType: 'space',
				resourceId: spaceId,
				activationId: '',
			})
		: '';
	// We leave activationId as an empty string because reading aids will loaded in a content type with activationId
	const workspaceId = getConfluenceContentAri({
		siteId: cloudId,
		resourceType: WORKSPACE_RESOURCE_TYPE,
		resourceId: activationId,
		activationId: '',
	});

	const {
		aiSearchQueryFunction,
		loading: searchSupplierLoading,
		assistanceServiceEnabled,
	} = useAISearchSupplier({
		contentId,
		onResult,
	});

	const { fetchDefinition, loading: searchAILoading } = useDefinitionSupplier({
		workspaceId,
		cloudIdARI,
		fetchConfig: {
			entity_ari: contentARI,
			...(isAssistanceServiceStreamingEnabled
				? {}
				: {
						timeout: READING_AIDS_ASSISTANCE_SERVICE_FETCH_TIMEOUT,
					}),
		},
		confluenceScopeId: {
			contentId: contentARI,
			spaceId: spaceARI,
		},
		isDefinitionCurationEnabled: true,
		isAssistantServiceStreamingEnabled: isAssistanceServiceStreamingEnabled,
	});

	useEffect(() => {
		if (!documentRef.current) {
			documentRef.current = document.querySelector(`#${LOADING_CONTAINER_ID}`);
		}
	}, [documentRef]);

	useEffect(() => {
		setQuery(selectedText);
	}, [selectedText]);

	// We want to parse only sentences containing the query after extracting the chunk
	// We will consider the sentence with the first occurrence of the query under 200 characters
	const additionalQueryContext = useMemo(() => {
		const contextChunk = additionalContext || extractReadingAidsContext(selectionRange);
		const sentenceRegex =
			/(?=[^])(?:\P{Sentence_Terminal}|\p{Sentence_Terminal}(?!['"`\p{Close_Punctuation}\p{Final_Punctuation}\s]))*(?:\p{Sentence_Terminal}+['"`\p{Close_Punctuation}\p{Final_Punctuation}]*|$)/guy;
		const contextSentences = contextChunk
			.match(sentenceRegex)
			?.filter((sentence) => !!sentence.match(query.replace(/[/\-\\^$*+?.()|[\]{}]/g, '\\$&')));
		if (contextSentences && contextSentences?.[0]?.length <= READING_AIDS_CONTEXT_MAX_CHARACTERS) {
			return contextSentences[0].trim();
		} else {
			return '';
		}
	}, [query, additionalContext, selectionRange]);

	const defineContentRef = (node: HTMLElement | null) => {
		setPopupRef(node);
	};

	const defineTriggerRef = (ref: any) => (node: HTMLElement | null) => {
		setTriggerRef(node);
		ref(node);
	};

	const onClosePopup = useCallback(() => {
		if (newLoadingStateEnabled && answerLoadingRef.current) {
			return;
		}
		onClose();
	}, [onClose, newLoadingStateEnabled]);

	useInlineDialogCloseManager({ onClose: onClosePopup, popupRef, triggerRef });

	const toggleAutoHighlightsDisabled = useCallback(
		() => {
			// this boolean's flipped cause its looking for the old state
			if (autoHighlightsDisabled) {
				localStorage.setItem(PERSISTED_KEYS_ON_SERVER.PERSISTED_READING_AIDS_AUTO_HIGHLIGHT, false);
				createAnalyticsEvent({
					type: 'sendUIEvent',
					data: {
						action: 'clicked',
						actionSubject: 'toggleDefinitionsOn',
						source,
						attributes: {
							readingAidsSessionId,
						},
					},
				}).fire();
			} else {
				localStorage.setItem(PERSISTED_KEYS_ON_SERVER.PERSISTED_READING_AIDS_AUTO_HIGHLIGHT, true);
				createAnalyticsEvent({
					type: 'sendUIEvent',
					data: {
						action: 'clicked',
						actionSubject: 'toggleDefinitionsOff',
						source,
						attributes: {
							readingAidsSessionId,
						},
					},
				}).fire();
			}
			setAutoHighlightsDisabled(!autoHighlightsDisabled);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[autoHighlightsDisabled],
	);

	const readingAidsMenuOptions = [
		{
			type: 'toggle' as const,
			toggleState: autoHighlightsDisabled,
			label: {
				id: 'contextual-reading-aids.menu-option.disable-all.header',
				defaultMessage: 'Don’t recommend definitions at all',
				description:
					'Header text for the reading aids menu option to not disable auto highlighting for all pages',
			},
			onChange: toggleAutoHighlightsDisabled,
			description: {
				id: 'contextual-reading-aids.menu-option.disable-all.description',
				defaultMessage:
					'When this is on, we’ll no longer automatically highlight terms and acronyms on any page. Only you will see these changes.',
				description:
					'Description text for the reading aids menu option to not disable auto highlighting for all pages',
			},
		},
	];

	const definitionRestrictionOptions = [
		{
			label: formatMessage(i18n.editDefinitionRestrictedToSpaceOption),
			value: EditedDefinitionScope.SPACE,
			icon: <DocumentsIcon label="audience-restriction-space" />,
		},
		contentType === 'blogpost'
			? {
					label: formatMessage(i18n.editDefinitionRestrictedToBlogpostOption),
					value: EditedDefinitionScope.BLOGPOST,
					icon: <QuoteIcon label="audience-restriction-quote" />,
				}
			: {
					label: formatMessage(i18n.editDefinitionRestrictedToPageOption),
					value: EditedDefinitionScope.PAGE,
					icon: <FileIcon label="audience-restriction-page" />,
				},
		{
			label: formatMessage(i18n.editDefinitionRestrictedToSiteOption),
			value: EditedDefinitionScope.ORGANIZATION,
			icon: <OfficeBuildingIcon label="audience-restriction-site" />,
		},
	];

	const curatedDefinitionScopeConfig = {
		workspaceId,
		definitionRestrictionOptions,
		productScopes: {
			contentARI,
			spaceARI,
		},
	};

	const loadingHighlightOption =
		newLoadingStateEnabled && !!loadingHighlightRect
			? {
					selectionRect: loadingHighlightRect,
					documentRef,
					darkModeEnabled: isDarkMode,
					handleResize: onClose,
				}
			: undefined;

	const definitionCurationOptions = {
		curatedDefinitionScopeConfig,
	};

	if (!portalContainer || !selectionRect) {
		return null;
	}

	/* eslint-disable jsx-a11y/no-autofocus */
	return ReactDOM.createPortal(
		<Popup
			autoFocus={false}
			testId="reading-aids-popup"
			content={({ update }) => (
				<PopupWrapper ref={defineContentRef}>
					<Fragment>
						<SearchAIDialog
							query={query}
							setQuery={setQuery}
							additionalQueryContext={additionalQueryContext}
							edition={edition?.toString()}
							getAIAnswer={
								fg('definition_enable_use_definition_supplier')
									? fetchDefinition
									: aiSearchQueryFunction
							}
							onNavigate={() => {}}
							searchSessionId=""
							userDetails={{ id: userId || '' }}
							cloudId={cloudId}
							isReadingAids
							onClose={onClosePopup}
							answerLoadingRef={answerLoadingRef}
							readingAidsOptions={{
								menuOptions: readingAidsMenuOptions,
								loadingHighlightOption,
								definitionCurationOptions,
							}}
							source={source}
							sourceProduct="Confluence"
							extraAnalyticsAttributes={{ contentId, readingAidsSessionId }}
							baseQuery={query}
							onLoad={update}
							assistanceServiceEnabled={assistanceServiceEnabled}
							followUpsEnabled={isReadingAidsShowFollowUpEnabled}
							workspaceId={workspaceId}
							brand={isRovoEnabled ? AiBrand.ROVO : AiBrand.AI}
							readingAidsStreamingExperimentEnabled={
								FeatureGates.getExperimentValue<string>(
									'reading_aids_streaming_experiment',
									'cohort',
									'control',
								) === 'experiment'
							}
						/>
					</Fragment>
					{!(fg('definition_enable_use_definition_supplier')
						? searchAILoading
						: searchSupplierLoading) && (
						<ExperienceSuccess name={READING_AIDS_EXPERIENCE} attributes={{ contentId }} />
					)}
				</PopupWrapper>
			)}
			isOpen={isReadingAidsOpen}
			onClose={onClosePopup}
			placement="bottom-start"
			popupComponent={CustomPopupContainer}
			trigger={({ ref, ...triggerProps }) => (
				<CurrentSelectionSpan
					data-testid="reading-aids-selection-span"
					ref={defineTriggerRef(ref)}
					{...triggerProps}
					top={selectionRect.top}
					left={selectionRect.left}
					width={selectionRect.width}
					height={selectionRect.height}
				/>
			)}
			zIndex={11}
		/>,
		portalContainer,
	);
};
